<template>
  <div class="w-home">
    <div class="w-title">康泰欢迎您！</div>
    <video class="w-video" muted autoplay="autoplay" loop="loop">
        <source src="../assets/kt.mp4" type="video/mp4" />
    </video>
    <a href="https://beian.miit.gov.cn/">蜀ICP备12002297号</a>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {

  }
}
</script>
<style>
.w-home {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(72, 129, 200);
  height: 100%;
  width: 100%;
}

.w-title {
  color: white;
  font-size: xxx-large;
  margin: 20px 0;
}

.w-video {
  margin: 10px;
}
</style>